var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-actions',{attrs:{"action-collapse":"","title":"Agent Form"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Agency Name")]),_c('validation-provider',{attrs:{"rules":"required","name":"agency_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Agency name"},model:{value:(_vm.input.agency_name),callback:function ($$v) {_vm.$set(_vm.input, "agency_name", $$v)},expression:"input.agency_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("First name")]),_c('validation-provider',{attrs:{"rules":"required","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"First Name"},model:{value:(_vm.input.firstname),callback:function ($$v) {_vm.$set(_vm.input, "firstname", $$v)},expression:"input.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Last Name"},model:{value:(_vm.input.lastname),callback:function ($$v) {_vm.$set(_vm.input, "lastname", $$v)},expression:"input.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.input.email),callback:function ($$v) {_vm.$set(_vm.input, "email", $$v)},expression:"input.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Phone")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Phone"},model:{value:(_vm.input.phone),callback:function ($$v) {_vm.$set(_vm.input, "phone", $$v)},expression:"input.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Password")]),_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false:null,"placeholder":"Your Password"},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Confirm Password")]),_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Address")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Address"},model:{value:(_vm.input.address),callback:function ($$v) {_vm.$set(_vm.input, "address", $$v)},expression:"input.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }